/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export const onServiceWorkerUpdateReady = () => {
  // const atualizar = window.confirm(
  //   "O app tem uma nova atualização, deseja aplicá-la agora?"
  // );

  // if (atualizar) {
  window.location.reload();
  // }
};